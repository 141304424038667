import axios from 'axios';

import { getSession } from './auth';
import config from './config';

const getAuthHeaders = async () => {
    const session = await getSession();

    const context = session
        ? {
              headers: {
                  Authorization: session.tokens.id,
              },
          }
        : {};

    return context;
};

const requestWrapper = async (request) => {
    try {
        const context = await getAuthHeaders();
        const response = await request(context);
        return response.data;
    } catch (err) {
        if (axios.isAxiosError(err) && err.response?.data?.error) {
            throw new Error(err.response.data.error);
        }
        throw err;
    }
};

class API {
    constructor() {
        this.client = axios.create({
            baseURL: config.apiEndpoint,
        });
    }

    async getUsers() {
        return requestWrapper(async (context) => {
            return this.client.get(`users/type/unified`, context);
        });
    }

    async getUser(userId) {
        return requestWrapper(async (context) => {
            return this.client.get(`users/type/unified/userId/${userId}`, context);
        });
    }

    async verifyUser(userId, method, body) {
        return requestWrapper(async (context) => {
            return this.client.post(`users/${userId}/verify/${method}`, body, context);
        });
    }

    async resetUser(userId, body) {
        return requestWrapper(async (context) => {
            return this.client.post(`users/${userId}/reset`, body, context);
        });
    }

    async updatePermissions(userId, body) {
        return requestWrapper(async (context) => {
            return this.client.post(`users/${userId}/permissions`, body, context);
        });
    }

    async updateUser(userId, body) {
        return requestWrapper(async (context) => {
            return this.client.patch(`users/${userId}`, body, context);
        });
    }

    async createUser(body) {
        return requestWrapper(async (context) => {
            return this.client.post('users', body, context);
        });
    }

    async getOnboardingOrganisations() {
        return requestWrapper(async (context) => {
            return this.client.get('onboarding/organisations', context);
        });
    }

    async getOnboardingUsers() {
        return requestWrapper(async (context) => {
            return this.client.get('onboarding/users', context);
        });
    }

    async getUserOnboarding(onboardingId) {
        return requestWrapper(async (context) => {
            return this.client.get(`onboarding/users/${onboardingId}`, context);
        });
    }

    async updateUserOnboarding(onboardingId, body) {
        return requestWrapper(async (context) => {
            return this.client.post(`onboarding/users/${onboardingId}/prescreen`, body, context);
        });
    }

    async getAllConfigs() {
        return requestWrapper(async (context) => {
            return this.client.get('configs', context);
        });
    }

    async updateUserContact(userId, method, body) {
        return requestWrapper(async (context) => {
            return this.client.post(`users/${userId}/update/${method}`, body, context);
        });
    }
}

const api = new API();
export default api;
